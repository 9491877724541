<template>
  <v-container class="fill-height" fluid>
    <v-app-bar
      app
      color="white"
      class="ma-2 mt-0 rounded-br-xl rounded-bl-xl pr-2 no-print"
      elevation="1"
    >
      <v-row align="center" justify="center">
        <v-col cols="12">
          <h3 class="text-center">Perfil de acesso</h3>
        </v-col>
      </v-row>
    </v-app-bar>

    <v-row align="center" justify="center" style="min-height: 300px">
      <v-col v-for="role in userRoles" :key="role" cols="12" sm="6" md="4">
        <v-card
          class="mx-auto"
          max-width="344"
          @click="navigateToProfile(role)"
        >
          <v-list-item three-line>
            <v-list-item-content>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  gap: 16px;
                "
              >
                <v-list-item-title>Entrar como</v-list-item-title>

                <div
                  style="
                    display: flex;
                    justify-content: center;
                    width: 80px;
                    height: 80px;
                    background-color: #568bbf;
                  "
                >
                  <v-icon style="font-size: 60px; color: white">{{
                    getIcon(role)
                  }}</v-icon>
                </div>
                <v-list-item-subtitle class="headline">
                  {{ getRoleLabel(role) }}
                </v-list-item-subtitle>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "SelectProfile",
  data() {
    return {
      selectedProfile: null,
    };
  },
  computed: {
    userRoles() {
      const roles = this.$store.getters["login/roles"];
      return roles.filter((role) => role !== "admin");
    },
  },
  methods: {
    navigateToProfile(role) {
      this.$store.dispatch("login/changeActiveRole", role);
    },

    getRoleLabel(role) {
      switch (role) {
        case "admin":
          return "Administrator";
        case "patient":
          return "Paciente";
        case "therapist":
          return "Terapeuta";
        default:
          return role;
      }
    },

    getIcon(role) {
      switch (role) {
        case "admin":
          return "mdi-account-circle";
        case "patient":
          return "mdi-account";
        case "therapist":
          return "mdi-doctor";
        default:
          return "mdi-account";
      }
    },
  },
};
</script>

<style scoped>
.text-primary {
  color: #1976d2;
}

.light-gray {
  color: #9e9e9e;
}
</style>
